import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useState} from "react";
import { Button } from "./Button";
import "react-datepicker/dist/react-datepicker.css";

//TODO finish updating form to match details page. Configure datepicker.

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 30%;
`;

const StyledLabel = styled.label`
    font-weight: 800;
    margin-inline-end: 0.5rem;
    
    &::after {
        content: ':';
    }
`;

const StyledTextInput = styled.input`
    padding: 0.5rem;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1rem;
`;

const StyledSelect = styled.select`
    padding: 0.5rem;
`;

const StyledTextArea = styled.textarea`
    padding: 0.5rem;
`;

function NewAquariumForm({ newAquariumHandler, formToggle }) {
    const [aquariumLabel, setAquariumLabel] = useState('');
    const [aquariumType, setAquariumType] = useState('');
    const [aquariumAge, setAquariumAge] = useState(new Date());
    const [aquariumDispVol, setAquariumDispVol] = useState('');
    const [aquariumSysVol, setAquariumSysVol] = useState('');
    const [aquariumCycled, setAquariumCycled] = useState(false);
    const [aquariumNotes, setAquariumNotes] = useState('');

    const calculateAquariumAge = (startDate) => {
        const currentDate = new Date();
        const calculatedTime = currentDate.getTime() - startDate.getTime();
        let calculatedAgeInDays = Math.floor(calculatedTime / (1000 * 3600 * 24));
        let calculatedAgeInYears, calculatedAgeInMonths, calculatedAge;
        if(calculatedAgeInDays > 365) {
            calculatedAgeInYears = Math.floor(calculatedAgeInDays / 365);
            calculatedAgeInDays = calculatedAgeInDays - (calculatedAgeInYears * 365);
            calculatedAgeInMonths = Math.floor(calculatedAgeInDays / 30.417);
        } else {
            calculatedAgeInMonths = Math.floor(calculatedAgeInDays / 30.417);
        }
        calculatedAge = `${calculatedAgeInYears > 0 ? `${calculatedAgeInYears} Year/s` : ''} 
        ${calculatedAgeInMonths > 0 ? `${calculatedAgeInMonths} Month/s` : ''}`;
        return calculatedAge;
    };

    const onSubmitHandler = (e) => {
      e.preventDefault();

      const submission = {
        'aquariumCycled': aquariumCycled,
        'aquariumLabel': aquariumLabel,
        'aquariumImg': 'https://cdn.shopify.com/s/files/1/0311/3149/articles/featured_image_-_high_tech_planted_tank.jpg?v=1662734533&width=1000',
        'aquariumType': aquariumType,
        'aquariumAge': calculateAquariumAge(aquariumAge),
        'aquariumDispVol': aquariumDispVol,
        'aquariumSysVol': aquariumSysVol,
        'aquariumNotes' : aquariumNotes
      };

      newAquariumHandler(submission);
    };
    return (
        <StyledForm method="post" onSubmit={onSubmitHandler}>
            <div>
                <StyledLabel htmlFor={'aquariumLabel'}>Aquarium Label</StyledLabel>
                <StyledTextInput
                    type={'text'}
                    id={'aquariumLabel'}
                    name={'aquariumLabel'}
                    value={aquariumLabel}
                    onChange={e => setAquariumLabel(e.target.value)}/>
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumType'}>Type</StyledLabel>
                <StyledSelect
                    id={'aquariumType'}
                    name={'aquariumType'}
                    onChange={e => setAquariumType(e.target.value)}>
                    <option>Freshwater</option>
                    <option>Saltwater</option>
                    <option>Brackish</option>
                    <option>Pond</option>
                </StyledSelect>
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumAge'}>Aquarium Start Date</StyledLabel>
                <DatePicker
                    id={'aquariumAge'}
                    selected={aquariumAge}
                    onChange={(date) => setAquariumAge(date)} />
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumDispVol'}>Display Volume</StyledLabel>
                <StyledTextInput
                    id={'aquariumDispVol'}
                    name={'aquariumDispVol'}
                    type={'text'}
                    value={aquariumDispVol}
                    onChange={e => setAquariumDispVol(e.target.value)}/>
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumSysVol'}>System Volume</StyledLabel>
                <StyledTextInput
                    id={'aquariumSysVol'}
                    name={'aquariumSysVol'}
                    type={'text'}
                    value={aquariumSysVol}
                    onChange={e => setAquariumSysVol(e.target.value)}/>
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumCycled'}>Cycled</StyledLabel>
                <StyledTextInput
                    id={'aquariumCycled'}
                    name={'aquariumCycled'}
                    type={'checkbox'}
                    value={'cycled'}
                    checkedState={aquariumCycled}
                    onChange={e => setAquariumCycled(e.target.checked)}/>
            </div>
            <div>
                <StyledLabel htmlFor={'aquariumNotes'}>Notes</StyledLabel>
                <StyledTextArea
                    id={'aquariumNotes'}
                    name={'aquariumNotes'}
                    rows={'4'}
                    cols={'50'}
                    onChange={e => setAquariumNotes(e.target.value)}/>
            </div>
            <StyledButtonContainer>
                <Button type={"submit"}>Submit</Button>
                <Button onClick={formToggle} inverted={true}>Cancel</Button>
            </StyledButtonContainer>
        </StyledForm>
    )
}

export default NewAquariumForm;