import './App.css';
import { useState } from 'react';
import styled from 'styled-components';
import { Theme } from './theme/Theme';
import { AquariumCard } from './components/AquariumCard';
import NewAquariumForm from './components/NewAquariumForm';
import { NewAquariumButton } from "./components/NewAquariumButton";
import { AquariumDetailsView } from "./components/AquariumDetailsView";

const StyledCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-block-end: 1rem;
`;

const StyledAppHeader = styled.h1`
    font-size: 3rem;
    font-weight: 800;
    color: ${props => props.theme.colors.primaryPurple};
    margin-block-start: 0;
`;

const StyledMain = styled.main`
    position: relative;
    display: grid;
    grid-template-columns: ${props => props.theme.contentGrid.grid};
    padding-block: 1rem 3rem;
    
    & > * {
        grid-column: ${props => props.theme.contentGrid.content};
    }
`;

function App() {
        let aquariumObject1 = {
            'id': 1,
            'aquariumCycled': true,
            'aquariumLabel': '40 Gal Reef',
            'aquariumImg': 'https://i.ytimg.com/vi/_LDIgTqVjgY/maxresdefault.jpg',
            'aquariumType': 'Saltwater',
            'aquariumAge': '2 years',
            'aquariumDispVol': '40 Gallons',
            'aquariumSysVol': '45 Gallons',
            'aquariumNotes': 'hello world',
            'aquariumFish': [
                {
                    'species': 'yellow tang',
                    'count': 1
                },
                {
                    'species': 'clown fish',
                    'count': 1
                }
            ],
            'aquariumInverts': [
                {
                    'species': 'halloween hermit crab',
                    'count': 1
                },
                {
                    'species': 'emerald crab',
                    'count': 2
                }
            ]
        };

    let aquariumObject2 = {
        'id': 2,
        'aquariumCycled': false,
        'aquariumLabel': 'Planted 10 Gal',
        'aquariumImg': 'https://cdn.shopify.com/s/files/1/0311/3149/articles/featured_image_-_high_tech_planted_tank.jpg?v=1662734533&width=1000',
        'aquariumType': 'Freshwater',
        'aquariumAge': '2 Months',
        'aquariumDispVol': '10 Gallons',
        'aquariumSysVol': 'N/A',
        'aquariumNotes': '',
        'aquariumFish': [{
            'species': 'celestial pearl danio',
            'count': 4
        }]
    };
    const [aquariums, setAquariums] = useState([aquariumObject1, aquariumObject2]);
    const [aquariumDetails, setAquariumDetails] = useState({});
    const [addNewToggle, setAddNewToggle] = useState(false);
    const toggleNewAquariumForm = () => {
        setAddNewToggle(!addNewToggle);
    };
    const newAquariumHandler = (newAquarium) => {
        setAquariums(
            [
                ...aquariums,
                newAquarium
            ]
        );
        toggleNewAquariumForm();
    };
    const detailsView = (aquariumId) => {
        setAquariumDetails(filterAquariums(aquariumId));
    };

    const filterAquariums = (aquariumId) => {
        return aquariums.filter(aquarium => aquarium.id === aquariumId)[0] || {};
    }
    const handleCardDelete = (aquariumId) => {
        let aquariumsArr = aquariums.filter(aquarium => aquarium.id !== aquariumId);
        setAquariums(aquariumsArr);
    }
    const aquariumList = aquariums.map((aquarium, key) => {
        return(<AquariumCard aquarium={aquarium} key={key} deleteHandler={handleCardDelete}
        detailsView={detailsView}/> );
    });
    let template;
    if(addNewToggle) {
        template = <NewAquariumForm formToggle={toggleNewAquariumForm} newAquariumHandler={newAquariumHandler}/>;
    }
    else if(!addNewToggle && Object.keys(aquariumDetails).length > 0) {
        template = <AquariumDetailsView aquariumDetails={aquariumDetails} detailsToggler={detailsView}/>
    } else {
        template = (
            <StyledCardList>
                {aquariumList}
            </StyledCardList>
        );
    }
  return (
      <Theme>
        <StyledMain>
            <StyledAppHeader>Aquarium Tracker</StyledAppHeader>
            {template}
            {!addNewToggle && Object.keys(aquariumDetails).length === 0 && <NewAquariumButton toggleHandler={toggleNewAquariumForm}/>}
        </StyledMain>
      </Theme>
  );
}

export default App;
