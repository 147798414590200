import { ThemeProvider } from 'styled-components';

let theme = {
    'contentGrid': {
        'grid': '[full-bleed-start] 100px [breakout-start] 100px [content-start] 1fr [content-end] 100px [breakout-end] 100px [full-bleed-end]',
        'fullBleed': 'full-bleed',
        'breakout': 'breakout',
        'content': 'content'
    },
    'colors': {
        'white': '#FFF',
        'black': '#000',
        'primaryPurple': '#7E157E',
        'darkBlue': '#204281',
        'darkerBlue': '#061F4E',
        'grey': 'grey',
        'lightGrey': '#E6E6E6',
        'green': '#219D1A',
        'darkGreen': '#056000',
    },

    'detailsPageGradient' : {
        // 'saltwater' : 'linear-gradient(0deg, rgba(126,21,126,1) 5%, rgba(126,21,126,1) 13%, rgba(126,21,126,0.9164040616246498) 20%, rgba(126,21,126,0.5186449579831933) 28%, rgba(126,21,126,0) 41%, rgba(126,21,126,0) 100%)',
        'saltwater' : (bgImg) => {return `linear-gradient(0deg, rgba(9,9,121,1) 6%, rgba(9,9,121,0.773546918767507) 19%, rgba(255,255,255,0) 36%), url('${bgImg}')`;},
        'freshwater' : (bgImg) => { return `linear-gradient(0deg, rgba(50,182,11,1) 6%, rgba(41,191,10,0.8939950980392157) 19%, rgba(255,255,255,0) 36%), url('${bgImg}')`}
    }
};

theme.buttons = {
    'primary' : {
        'background' : theme.colors.darkBlue,
        'text' : theme.colors.white,
        'border' : 'none',
        'hoverBackground' : theme.colors.darkerBlue
    },
    'inverted' : {
        'background' : theme.colors.white,
        'text' : theme.colors.primaryPurple,
        'border' : `solid 1px ${theme.colors.primaryPurple}`,
        'hoverBackground' : theme.colors.primaryPurple,
        'hoverText' : theme.colors.white
    }
}

export const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);