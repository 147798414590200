import styled from "styled-components";

const StyledButton = styled.button.attrs((props) => ({
    'color' : props.inverted ? props.theme.buttons.inverted.text : props.theme.buttons.primary.text,
    'background' : props.inverted ? props.theme.buttons.inverted.background : props.theme.buttons.primary.background,
    'border' : props.inverted ? props.theme.buttons.inverted.border : props.theme.buttons.primary.border,
    'hoverBackground' : props.inverted ? props.theme.buttons.inverted.hoverBackground : props.theme.buttons.primary.hoverBackground,
    'hoverText' : props.inverted ? `color: ${props.theme.buttons.inverted.hoverText}` : ''
}))`
    padding-inline: 0.7em;
    padding-block: 0.5em;
    font-size: 1em;
    color: ${props => props.color};
    background-color: ${props => props.background};
    border: ${props => props.border};
    border-radius: 5px;
    transition: background-color 0.5s ease-in-out;
    
    &:hover {
        cursor: pointer;
        background-color: ${props => props.hoverBackground};
        ${props => props.hoverText}
    }
`;

export const Button = ({onClick, children, inverted, className}) => {
    return (
        <StyledButton className={className} inverted={inverted} onClick={onClick}>{children}</StyledButton>
    );
}