import styled from 'styled-components';

const StyledInfoGroupLabel = styled.span`
    font-weight: 800;
`;

const StyledInfoGroupContent = styled.span`
    color: ${props => props.blank ? props.theme.colors.grey : props.theme.colors.black};
    font-weight: ${props => props.blank ? '400' : ''};
`;

export const LabelInfoGroup = ({className, label, content}) => {
    let checkedContent = content || 'N/A';
    return(
        <div className={className}>
            <StyledInfoGroupLabel>{label}: </StyledInfoGroupLabel>
            <StyledInfoGroupContent blank={checkedContent === 'N/A' ? true : false}>{checkedContent}</StyledInfoGroupContent>
        </div>
    );
};