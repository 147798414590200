import styled from "styled-components";
import { Button } from './Button';
import { LabelInfoGroup } from "./LabelInfoGroup";

const StyledAquariumName = styled.h2`
    margin: 0;
`;

const StyledHeader = styled.div`
    //todo fix the cascade issue
    
    grid-column: ${props => props.theme.contentGrid.fullBleed} !important;
    // background-image: linear-gradient(0deg, rgba(126,21,126,1) 5%, rgba(126,21,126,1) 13%, rgba(126,21,126,0.9164040616246498) 20%, rgba(126,21,126,0.5186449579831933) 28%, rgba(126,21,126,0) 41%, rgba(126,21,126,0) 100%), 
    // url(${props => props.bgImg});
    background-image: ${props => props.aquariumType === 'saltwater' ? props.theme.detailsPageGradient.saltwater(props.bgImg) : props.theme.detailsPageGradient.freshwater(props.bgImg)};

    background-size: cover;
    background-repeat: no-repeat;
    color: ${props => props.theme.colors.white};
`;

const StyledGlassContainer = styled.div`
    grid-column: ${props => props.theme.contentGrid.fullBleed};
    display: grid;
    grid-template-columns: ${props => props.theme.contentGrid.grid};
    background: rgba(0, 0, 0, 0.30);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.7px);
    padding-block: 1rem;
    
    & > * {
        grid-column: ${props => props.theme.contentGrid.content};
    }
`;

const StyledTankImg = styled.img`
    display: block;
    width: 30%;
    margin-inline: auto;
`;

export const AquariumDetailsView = ({aquariumDetails, detailsToggler}) => {

    const getAnimalListItems = (animalList) => {
        let animalListItems = null;
        if(animalList) {
            animalListItems =  animalList.map((animal, key) => {
                return(<li key={key}>
                    <LabelInfoGroup
                        label={"Species"}
                        content={animal.species}
                    />
                    <LabelInfoGroup
                        label={"Count"}
                        content={animal.count}
                        />
                </li>);
            });
        }
        return animalListItems;
    };
    const getAnimalList = (animalList) => {
        const formattedAnimalListItems = getAnimalListItems(animalList);
        if(formattedAnimalListItems != null) {
            return (
                <ul>{formattedAnimalListItems}</ul>
            );
        } else {
            return null;
        }
    }

    const fishList = getAnimalList(aquariumDetails.aquariumFish);
    const invertList = getAnimalList(aquariumDetails.aquariumInverts);

    return (
        <>
            <StyledHeader bgImg={aquariumDetails.aquariumImg} aquariumType={aquariumDetails.aquariumType}>
                <StyledGlassContainer>
                    <StyledTankImg src={aquariumDetails.aquariumImg}/>
                    <StyledAquariumName >{aquariumDetails.aquariumLabel}</StyledAquariumName>
                </StyledGlassContainer>
            </StyledHeader>

            <LabelInfoGroup
                label={"Type"}
                content={aquariumDetails.aquariumType}
            />
            <LabelInfoGroup
                label={"Approx. Age"}
                content={aquariumDetails.aquariumAge}
            />
            <LabelInfoGroup
                label={"Cycled"}
                content={aquariumDetails.aquariumCycled ? 'yes' : 'no'}
            />
            <LabelInfoGroup
                label={"Display Volume"}
                content={aquariumDetails.aquariumDispVol}
            />
            <LabelInfoGroup
                label={"System Volume"}
                content={aquariumDetails.aquariumSysVol}
            />
            <LabelInfoGroup
                label={"Notes"}
                content={aquariumDetails.aquariumNotes}
            />
            <LabelInfoGroup
                label={"Fish"}
                content={fishList ||''}/>
            <LabelInfoGroup
                label={"Inverts"}
                content={invertList || ''}/>
            <Button onClick={detailsToggler}>Back to List View</Button>
        </>
    );
}