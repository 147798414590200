import { Card } from './Card'
import { LabelInfoGroup } from './LabelInfoGroup';
import { Button } from "./Button";
import styled from 'styled-components';

const StyledAquariumCard = styled(Card)`
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 1rem;
    font-size: 0.9rem;
    transition: transform 0.3s ease-in-out;
`;

const StyledAquariumImg = styled.img`
    background: grey;
    width: 9rem;
    height: 6rem;
`;

const StyledInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 
        'title title cycled'
        'type age edit'
        'displayVol sysVol delete';
    gap: 1rem;
`;

const StyledH2 = styled.h3`
    grid-area: title;
    color: ${props => props.theme.colors.primaryPurple};
    font-size: 1.5rem;
    margin: 0;
`;

const StyledCycledStatus = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    font-weight: 800;
    grid-area: cycled;
`;

const StyledCycledIcon = styled.img`
    vertical-align: middle;
    width: 20px;
    height: 20px;
`;

const StyledTypeInfo = styled(LabelInfoGroup)`
    grid-area: type;
`;

const StyledAgeInfo = styled(LabelInfoGroup)`
    grid-area: age;
`;

const StyledDisplayVol = styled(LabelInfoGroup)`
    grid-area: displayVol;
`;

const StyledSystemVol = styled(LabelInfoGroup)`
    grid-area: sysVol;
`;

const StyledDeleteButton = styled(Button)`
    grid-area: delete;
`;

const StyledDetailsButton = styled (Button)`
    grid-area: edit;
`;

export const AquariumCard = ({aquarium, deleteHandler, detailsView}) => {
    let cycledCheck = aquarium.aquariumCycled === true ? 'greenCheck' : 'redCheck';
  return(
      <StyledAquariumCard>
          <StyledAquariumImg src={aquarium.aquariumImg} alt={aquarium.aquariumImgAltText}/>
          <StyledInfoContainer>
              <StyledH2>{aquarium.aquariumLabel}</StyledH2>
              <StyledCycledStatus>
                  Cycled:
                  <StyledCycledIcon src={`assets/${cycledCheck}.png`} alt={aquarium.aquariumCycledStatusAltText}/>
              </StyledCycledStatus>
              <StyledTypeInfo label={'Type'} content={aquarium.aquariumType}/>
              <StyledAgeInfo label={'Approx. Age'} content={aquarium.aquariumAge}/>
              <StyledDisplayVol label={'Display Volume'} content={aquarium.aquariumDispVol}/>
              <StyledSystemVol label={'System Volume'} content={aquarium.aquariumSysVol}/>
              <StyledDetailsButton
                  onClick={() => detailsView(aquarium.id)}>
                  Details</StyledDetailsButton>
              <StyledDeleteButton
                  inverted={true}
                  onClick={() => deleteHandler(aquarium.id)}>
                      Delete</StyledDeleteButton>
          </StyledInfoContainer>
      </StyledAquariumCard>
  );
};