import styled from 'styled-components';

const StyledCard = styled.div`
    padding: 2rem;
    border: solid 1px ${props => props.theme.colors.lightGrey};
    border-radius: 5px;
    box-shadow: -4px 4px 5px 1px rgb(0 0 0 / 6%);
    background: ${props => props.theme.colors.white};
    max-width: 550px;
`;

export const Card = ({className, children}) => {
    return(
      <StyledCard className={className}>{children}</StyledCard>
    );
};