import styled from 'styled-components';

const BUTTON_SIZE = '3rem';

const StyledButton = styled.button`
    position: sticky;
    left: 93vw;
    bottom: 5vh;
    width: ${BUTTON_SIZE};
    height: ${BUTTON_SIZE};
    border-radius: 50%;
    border: none;
    background-color: ${props => props.theme.colors.green};
    color: white;
    font-weight: 800;
    font-size: 2rem;
    transition: background-color 0.5s ease-in-out;
    
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.darkGreen};
    }
`;

export const NewAquariumButton = ({ toggleHandler }) => {
    return (<StyledButton onClick={toggleHandler} aria-label={'Add new aquarium'}>+</StyledButton>);
};